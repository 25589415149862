// utils/auth.js
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setIsLoggedIn, setUsernameAction, setRoValue, setGpiValue } from '../redux/actions';

export const verifyToken = async (token, dispatch, navigate) => {
    if (!token) {
        console.log('토큰이 없습니다.2');
        return;
    }
    console.log('보내는 토큰:', token);

    try {
        const response = await axios.post('http://farm.hdeng.net:4000/api/auth/verifyToken', {}, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (response.status === 200 && response.data.user) {
            console.log("토큰 검증 성공:", response.data);
            const user = response.data.user;

            dispatch(setIsLoggedIn(true));
            dispatch(setUsernameAction(user.username));
            dispatch(setRoValue(user.ro));

            // GPI 값 처리
            let gpiValue = user.gpi && typeof user.gpi === 'string' ? user.gpi.split(', ') : [];
            dispatch(setGpiValue(gpiValue));

            // // 리디렉션 결정
            // if (user.gpi) {
            //     navigate('/gpi');
            // } else {
            //     navigate('/ro/user');
            // }
        }
    } catch (error) {
        console.error("토큰 검증 실패, 오류:", error);
        console.error("실패 응답:", error.response ? error.response.data : '응답 없음');

        localStorage.removeItem('token');
        dispatch(setIsLoggedIn(false));
    }
};