// src/redux/reducers.js

import { SET_RO_VALUE, SET_USERNAME, SET_IS_LOGGED_IN, SET_GPI_VALUE } from './actions';

const initialState = {
    roValue: null,
    username: null,
    isLoggedIn: false, // 로그인 상태 초기값
    gpiValue: null, // GPI 값 추가
};

export const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RO_VALUE:
            return {
                ...state,
                roValue: action.payload,
            };
        case SET_USERNAME:
            return {
                ...state,
                username: action.payload,
            };
        case SET_IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.payload,
            };
        case SET_GPI_VALUE: // GPI 값 설정 케이스 추가
            return {
                ...state,
                gpiValue: action.payload,
            };
        default:
            return state;
    }
};
