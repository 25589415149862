import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Container, Form, Modal, Table } from 'react-bootstrap';
import CustomerCsv from './CustomerCsv';
import CustomerCsvDown from './CustomerCsvDown';

function CustomerList() {
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so +1
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [customers, setCustomers] = useState([]);
    const [newCustomer, setNewCustomer] = useState({
        serial: 'RO', // serial 필드 추가
        user: '',
        model: 'RO801',
        install: getCurrentDate(),
        phone: '',
        address: '',
        rental: '',
        email: '',
        password: '',
        memo: '',
        price: '',
    });

    const [showAddModal, setShowAddModal] = useState(false); // 추가 모달 상태
    const [showEditModal, setShowEditModal] = useState(false); // 수정 모달 상태
    const [editCustomerId, setEditCustomerId] = useState(null); // 수정 중인 고객 ID
    const [editedCustomer, setEditedCustomer] = useState({}); // 수정된 고객 정보

    // 입력 필드 정보 배열
    const inputFields = [
        { label: 'Serial', key: 'serial' },
        { label: 'User', key: 'user' },
        { label: 'Model', key: 'model' },
        { label: 'Install', key: 'install' },
        { label: 'Phone', key: 'phone' },
        { label: 'Address', key: 'address' },
        { label: 'Rental', key: 'rental' },
        { label: 'Email', key: 'email' },
        { label: 'Password', key: 'password' },
        { label: 'Memo', key: 'memo' },
        { label: 'Price', key: 'price' },
    ];

    // 서버 주소를 localhost:4000로 설정
    const serverUrl = 'http://farm.hdeng.net:4000';

    useEffect(() => {
        // 서버에서 고객 목록을 가져오는 API 호출
        fetchCustomerList();
    }, []);

    const fetchCustomerList = () => {
        axios
            .get(`${serverUrl}/ro/customer`)
            .then((response) => {
                setCustomers(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleDelete = (customerId) => {
        // 서버에 해당 고객을 삭제하는 API 호출
        axios
            .delete(`${serverUrl}/ro/customer/${customerId}`)
            .then(() => {
                // 삭제가 성공하면 고객 목록을 다시 가져옴
                fetchCustomerList();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleEdit = (customerId) => {
        // 고객 정보 수정을 시작하면 해당 고객 정보를 가져옴
        axios
            .get(`${serverUrl}/ro/customer/${customerId}`)
            .then((response) => {
                setEditCustomerId(customerId);
                setEditedCustomer(response.data);
                setShowEditModal(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSaveEdit = () => {
        // 수정된 고객 정보를 서버에 업데이트하는 API 호출
        axios
            .put(`${serverUrl}/ro/customer/${editCustomerId}`, editedCustomer)
            .then(() => {
                // 수정이 성공하면 수정 모달을 닫고 고객 목록을 다시 가져옴
                setShowEditModal(false);
                fetchCustomerList();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Add 버튼 클릭됨'); // 버튼 클릭 시 로그 출력

        // 새로운 고객 정보를 서버에 추가하는 API 호출
        axios
            .post(`${serverUrl}/ro/customer`, newCustomer)
            .then(() => {
                // 추가가 성공하면 고객 목록을 다시 가져옴
                setNewCustomer({
                    user: '',
                    model: '',
                    install: '',
                    phone: '',
                    address: '',
                    rental: '',
                    email: '',
                    password: '',
                    memo: '',
                    price: '',
                }); // 입력 필드 초기화
                fetchCustomerList();
            })
            .catch((error) => {
                console.error('고객 추가 오류:', error);
            });
    };

    const [showCsvInfoModal, setShowCsvInfoModal] = useState(false);

    return (
        <Container>
            <h2>Customer List</h2>
            <div className="button-container" style={{ display: 'flex', gap: '10px' }}>
                <Button variant="primary" onClick={() => setShowAddModal(true)}>Add Customer</Button>
                <CustomerCsv refreshData={fetchCustomerList} />
                <CustomerCsvDown data={customers} />
                <Button variant="info" onClick={() => setShowCsvInfoModal(true)}>CSV Info</Button>
            </div>

            {/* CSV 형식 설명 모달 */}
            <Modal show={showCsvInfoModal} onHide={() => setShowCsvInfoModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>CSV Format Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    CSV 파일은 다음 형식을 따라야 합니다:
                    <pre>
                        serial, user, model, install, phone, address, rental, email, password, memo, price
                    </pre>
                    각 항목은 콤마(,)로 구분되어야 합니다.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCsvInfoModal(false)}>
                        Close
                    </Button>
                    <Button variant="success" href="/customer.csv" download>
                        Download Sample CSV
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* 고객 추가 모달 */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        {inputFields.map((field) => (
                            <Form.Group key={field.key}>
                                <Form.Label>{field.label}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={field.label}
                                    value={newCustomer[field.key]}
                                    onChange={(e) => setNewCustomer({ ...newCustomer, [field.key]: e.target.value })}
                                />
                            </Form.Group>
                        ))}
                        <Button variant="primary" type="submit">
                            Add
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* 고객 수정 모달 */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {inputFields.map((field) => (
                            <Form.Group key={field.key}>
                                <Form.Label>{field.label}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={field.label}
                                    value={editedCustomer[field.key]}
                                    onChange={(e) => setEditedCustomer({ ...editedCustomer, [field.key]: e.target.value })}
                                />
                            </Form.Group>
                        ))}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveEdit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        {inputFields.map((field) => (
                            <th key={field.key}>{field.label}</th>
                        ))}
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map((customer) => (
                        <tr key={customer.id}>
                            {inputFields.map((field) => (
                                <td key={field.key}>{customer[field.key]}</td>
                            ))}
                            <td>
                                <Button variant="danger" onClick={() => handleDelete(customer.id)}>Delete</Button>
                                <Button variant="info" onClick={() => handleEdit(customer.id)}>Edit</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}

export default CustomerList;
