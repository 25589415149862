// src/pages/ro/RoAdmin.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import RoTable from './RoTable'; // RoTable 컴포넌트 임포트
import RoSelect from './RoSelect'; // RoSelect 컴포넌트 임포트
import RoChart from './RoChart'; // 차트 컴포넌트 임포트
import './RoAdmin.css'; // CSS 파일 임포트

const RoAdmin = () => {
    const [tables, setTables] = useState([]);
    const [customerData, setCustomerData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedTable, setSelectedTable] = useState('');
    const [combinedData, setCombinedData] = useState([]);


    useEffect(() => {
        axios.get('http://farm.hdeng.net:4000/ro/tables')
            .then(response => {
                const { tables, customerData } = response.data;
                if (Array.isArray(tables)) {
                    setTables(tables);
                } else {
                    console.error("Received 'tables' data is not an array:", tables);
                }
                if (Array.isArray(customerData)) {
                    setCustomerData(customerData);
                    console.log('Customer Data:', customerData); // 여기에서 customerData를 콘솔에 출력합니다.
                    // Combine tables and customerData
                    const combined = tables.map((table, index) => {
                        return `${table} (${customerData[index]})`;
                    });
                    setCombinedData(combined); // Store the combined data
                } else {
                    if (!Array.isArray(tables)) {
                        console.error("Received 'tables' data is not an array:", tables);
                    }
                    if (!Array.isArray(customerData)) {
                        console.error("Received 'customerData' is not an array:", customerData);
                    }
                }
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
            });
    }, []);

    const fetchTableData = (tableName, date) => {
        // 선택된 테이블 이름을 상태로 관리합니다.
        setSelectedTable(tableName);
        // 날짜가 없는 경우 현재 날짜를 사용합니다.
        const formattedDate = date ? date.toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
        axios.get(`http://farm.hdeng.net:4000/ro/table/${tableName}?date=${formattedDate}`)
            .then(response => {
                // response.data가 배열인지 확인
                if (Array.isArray(response.data.data)) { // response.data.data 가 배열인지 확인합니다.
                    setSelectedData(response.data.data); // 데이터 업데이트
                } else {
                    console.error("Received data is not an array:", response.data.data);
                    setSelectedData([]); // 데이터 형식이 올바르지 않다면, 빈 배열로 초기화
                }
            })
            .catch(error => {
                console.error("Error fetching table data: ", error);
                setSelectedData([]); // 에러 발생 시 데이터를 비움
            });
    };

    const handleSelectTable = (tableName) => {
        // 선택된 테이블 이름을 상태로 관리합니다.
        setSelectedTable(tableName);
        // 날짜 없이 데이터를 가져옵니다.
        fetchTableData(tableName);
    };

    const handleDateSelect = (dateString) => {
        // dateString을 Date 객체로 변환합니다.
        const date = new Date(dateString);

        // 변환된 date가 유효한 Date 객체인지 확인합니다.
        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateString);
            return; // 유효하지 않은 날짜면 함수 실행을 멈춥니다.
        }

        // 선택된 테이블이 있으면 그 테이블에 대한 데이터를 가져옵니다.
        if (selectedTable) {
            fetchTableData(selectedTable, date);
        }
    };

    return (
        <Container fluid>
            <Row className="flex-wrap"> {/* flex-wrap으로 변경하여 내용을 강제로 한 줄에 넣지 않음 */}
                <Col xs={12} md={3} lg={2} className="list-group-col px-0"> {/* 여백을 제거하기 위해 px-0 추가 */}
                    <ListGroup variant="flush" className="list-group-custom">
                        {combinedData.map((data, index) => (
                            <ListGroup.Item
                                key={index}
                                action
                                onClick={() => handleSelectTable(tables[index])}
                                className="text-truncate" /* 텍스트가 너무 길 경우 말줄임표로 처리 */
                            >
                                {data}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col xs={12} md={9} lg={10} className="main-content-col"> {/* 내용 부분 */}
                    <RoSelect onDateSelect={handleDateSelect} />
                    <RoChart data={selectedData} />
                    <RoTable data={selectedData} />
                </Col>
            </Row>
        </Container>
    );
};

export default RoAdmin;