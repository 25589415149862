import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table, Form, Button } from 'react-bootstrap';
import { BsPencilSquare } from 'react-icons/bs';
import Glossary from './Glossary';
import { fetchLatestData, fetchCname, updateCname } from './GpiApi';

const getDewPointStatus = (dewPointGap) => {
    if (dewPointGap >= 3) {
        return '우수';
    } else if (dewPointGap >= 1) {
        return '양호';
    } else if (dewPointGap >= 0) {
        return '주의';
    } else {
        return '결로발생';
    }
};

const getDewPointColor = (dewPointGap) => {
    if (dewPointGap >= 5) {
        return '#C0C0C0'; // Silver
    } else if (dewPointGap >= 3) {
        return '#007bff'; // Blue
    } else if (dewPointGap >= 1) {
        return '#28a745'; // Green
    } else if (dewPointGap >= 0) {
        return '#fd7e14'; // Orange
    } else {
        return '#dc3545'; // Red
    }
};

function GpiLive({ gpiData }) {
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const [latestData, setLatestData] = useState({});
    const [cname, setCname] = useState({});
    const [editMode, setEditMode] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const latestDataPromises = gpiData.map(async (gpi) => {
                const data = await fetchLatestData(gpi);
                return { gpi, data };
            });

            const cnamePromises = gpiData.map(async (gpi) => {
                const data = await fetchCname(gpi);
                return { gpi, cname: data };
            });

            const latestDataResults = await Promise.all(latestDataPromises);
            const cnameResults = await Promise.all(cnamePromises);

            setLatestData(latestDataResults.reduce((acc, { gpi, data }) => ({ ...acc, [gpi]: data }), {}));
            setCname(cnameResults.reduce((acc, { gpi, cname }) => ({ ...acc, [gpi]: cname }), {}));
        };

        fetchData();

        const intervalId = setInterval(() => {
            setLastUpdated(new Date());
            fetchData();
        }, 60 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [gpiData]);

    const handleCnameChange = (gpi, newCname) => {
        setCname((prevCname) => ({
            ...prevCname,
            [gpi]: newCname,
        }));
    };

    const handleCnameSave = async (gpi) => {
        try {
            await updateCname(gpi, cname[gpi]);
            setEditMode((prevEditMode) => ({
                ...prevEditMode,
                [gpi]: false,
            }));
        } catch (error) {
            console.error('Failed to update cname:', error.response || error.message || error);
        }
    };

    const mapping = {
        opTime: { name: '전송시각', unit: 'Time' },
        EI_FG_TI: { name: '건구온도', unit: '℃' },
        EI_FG_TW: { name: '습구온도', unit: '℃' },
        EI_FG_TF: { name: '과실온도', unit: '℃' },
        EI_FG_TS: { name: '토양온도', unit: '℃' },
        EI_FG_TL: { name: '습구물통', unit: '수위' },
        EI_FG_HD: { name: 'HD', unit: 'g/m³' },
        EI_FG_DP: { name: '이슬점', unit: '℃' },
        EI_FG_CI: { name: 'CO2', unit: 'ppm' },
        EI_FG_HI: { name: '습도', unit: '%' },
        EI_FG_GP: { name: '결로점', unit: '℃' },
    };

    const summaryData = gpiData.map((gpi, index) => {
        const data = latestData[gpi] || {};
        const dewPointGap = data.EI_FG_GP; // 결로점 사용
        return {
            number: index + 1,
            cname: cname[gpi] || 'cname',
            temperature: data.EI_FG_TI,
            humidity: data.EI_FG_HI,
            dewPoint: data.EI_FG_GP, // 결로점
            dewPointStatus: getDewPointStatus(dewPointGap),
            dewPointColor: getDewPointColor(dewPointGap)
        };
    });

    return (
        <div>
            <h2>업데이트 시각 : {lastUpdated.toLocaleTimeString()}</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>이름</th>
                        <th>온도</th>
                        <th>습도</th>
                        <th>결로점</th>
                        <th>상태</th>
                    </tr>
                </thead>
                <tbody>
                    {summaryData.map((item, idx) => (
                        <tr key={idx}>
                            <td>{item.number}</td>
                            <td>{item.cname}</td>
                            <td>{item.temperature || 'N/A'}</td>
                            <td>{item.humidity || 'N/A'}</td>
                            <td>{item.dewPoint || 'N/A'}</td>
                            <td style={{ color: item.dewPointColor, fontWeight: 'bold' }}>{item.dewPointStatus}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Row xs={1} md={3} className="g-4">
                {gpiData.map((gpi) => (
                    <Col key={gpi}>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    {editMode[gpi] ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Form.Control
                                                type="text"
                                                value={cname[gpi] || ''}
                                                onChange={(e) => handleCnameChange(gpi, e.target.value)}
                                            />
                                            <Button
                                                variant="outline-success"
                                                size="sm"
                                                onClick={() => handleCnameSave(gpi)}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>{cname[gpi] || 'cname'}</span>
                                            <BsPencilSquare
                                                style={{ cursor: 'pointer', marginLeft: '10px' }}
                                                onClick={() => setEditMode((prevEditMode) => ({
                                                    ...prevEditMode,
                                                    [gpi]: true,
                                                }))}
                                            />
                                        </div>
                                    )}
                                    ({gpi})
                                </Card.Title>
                                {!latestData[gpi] ? (
                                    <p>정보 없음</p>
                                ) : (
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>항목</th>
                                                <th>측정값</th>
                                                <th>단위</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(latestData[gpi]).map(([key, value], idx) => (
                                                mapping[key] && (
                                                    <tr key={idx}>
                                                        <td>{mapping[key].name}</td>
                                                        <td>{value}</td>
                                                        <td>{mapping[key].unit}</td>
                                                    </tr>
                                                )
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Glossary /> {/* Glossary 컴포넌트를 페이지 하단에 추가 */}
        </div>
    );
}

export default GpiLive;
