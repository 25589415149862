import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

const RoChart = ({ data }) => {
    const chartData = data.map(item => ({
        ...item,
        '퇴수량(일/톤)': item.lpd1 / 1000,
        '정수량(일/톤)': item.lpd2 / 1000,
        '퇴수량(분/L)': item.lpm1,
        '정수량(분/L)': item.lpm2,
        '온도(C)': item.temp,
        '압력(Bar)': item.pressure > 1000 ? 0 : item.pressure  // Check if pressure exceeds 1000 and set to 0 if true
    }));

    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="퇴수량(분/L)" stroke="#8884d8" dot={false} />
                <Line type="monotone" dataKey="정수량(분/L)" stroke="#82ca9d" dot={false} />
                <Line type="monotone" dataKey="퇴수량(일/톤)" stroke="#8884d8" dot={false} strokeWidth={3} />
                <Line type="monotone" dataKey="정수량(일/톤)" stroke="#82ca9d" dot={false} strokeWidth={3} />
                <Line type="monotone" dataKey="온도(C)" stroke="#ff0000" dot={false} />
                <Line type="monotone" dataKey="압력(Bar)" stroke="#ffa500" dot={false} />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default RoChart;
