import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const GpiChart = ({ width, height, data }) => {
    const modifiedData = data.map((item) => ({
        ...item,
        EI_FG_GP_Modified: item.EI_FG_GP < 0 ? 50 : 0, // EI_FG_GP가 음수이면 50, 양수이면 0으로 설정
    }));

    const getDateTime = (dateTimeStr) => {
        const [datePart, timePart] = dateTimeStr.split(' ');
        const [hours, minutes, seconds] = timePart.split(':');
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds);
        return date;
    };

    const getTimeOnlyFromDate = (date) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        // 시간과 분을 항상 두 자리 숫자로 포맷팅
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;

        // "시:분" 형식의 문자열 반환
        return `${hours}:${minutes}`;
    };

    return (
        <LineChart
            width={width}
            height={height}
            data={data.slice().sort((a, b) => new Date(a.opTime) - new Date(b.opTime))}
            margin={{
                top: 5, right: 30, left: 20, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={(data) => getDateTime(data.opTime)} tickFormatter={getTimeOnlyFromDate} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="EI_FG_TI" stroke="#8884d8" dot={false} name="내부온도" />
            <Line type="monotone" dataKey="EI_FG_TF" stroke="#82ca9d" dot={false} name="과실온도" />
            <Line type="monotone" dataKey="EI_FG_HI" stroke="#ffc658" dot={false} name="내부습도" />
            <Line type="monotone" dataKey="EI_FG_DP" stroke="#ff7300" dot={false} name="이슬점" />
            <Line type="monotone" dataKey="EI_FG_GP_Modified" stroke="#0000FF" dot={false} name="결로점" />
        </LineChart>
    );
};

export default GpiChart;
