import React from 'react';
import { Table } from 'react-bootstrap';

const RoTable = ({ data }) => {
    if (!data || data.length === 0) {
        return <div>데이터가 없습니다.</div>;
    }

    // 데이터의 첫 번째 아이템에서 테이블 헤더를 동적으로 생성합니다.
    const headers = Object.keys(data[0]);

    // 데이터 배열을 역순으로 정렬합니다.
    const reversedData = [...data].reverse(); // 데이터 복사본을 만들어서 역순으로 정렬

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {reversedData.map((row, rowIndex) => ( // 역순 데이터 사용
                    <tr key={rowIndex}>
                        {headers.map((header, index) => (
                            <td key={index}>{row[header]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default RoTable;
