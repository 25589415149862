import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RoTable from './RoTable';
import RoChart from './RoChart';
import RoSelect from './RoSelect';
import RoAnalysis from './RoAnalysis';
import './RoUser.css';

const RoUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const ro = useSelector(state => state.roValue);
    const username = useSelector(state => state.username); // 사용자 이름을 가져옴
    const [averageLpm1, setAverageLpm1] = useState(0);
    const [averageLpm2, setAverageLpm2] = useState(0);
    const [lastLpd1, setLastLpd1] = useState(0);
    const [lastLpd2, setLastLpd2] = useState(0);
    const [showTable, setShowTable] = useState(false); // New state for table visibility
    const isLoggedIn = useSelector(state => state.isLoggedIn);

    useEffect(() => {
        // 로그인 상태가 아니면 로그인 페이지로 리디렉션
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        if (ro) {
            setData([]); // 데이터 초기화
            fetchData(ro, selectedDate);
        }
    }, [ro, selectedDate]);

    useEffect(() => {
        // Perform the calculation when 'data' changes
        if (data.length > 0) {
            calculateAverages();
        }
    }, [data]);

    useEffect(() => {
        if (data.length > 0) {
            calculateAverages();

            // Set the last values of lpd1 and lpd2
            const lastEntry = data[data.length - 1];
            if (lastEntry) {
                setLastLpd1(lastEntry.lpd1);
                setLastLpd2(lastEntry.lpd2);
            }
        }
    }, [data]);

    const calculateAverages = () => {
        // Initialize arrays to store valid lpm1 and lpm2 values
        let validLpm1 = [];
        let validLpm2 = [];

        // Populate the arrays with valid data
        data.forEach(entry => {
            const lpm1 = parseFloat(entry.lpm1);
            const lpm2 = parseFloat(entry.lpm2);

            if (lpm1 > 1) validLpm1.push(lpm1);
            if (lpm2 > 1) validLpm2.push(lpm2);
        });

        // Function to calculate average
        const calculateAverage = (array) => array.length > 0 ? array.reduce((a, b) => a + b, 0) / array.length : 0;

        // Set the state with the calculated averages
        setAverageLpm1(calculateAverage(validLpm1));
        setAverageLpm2(calculateAverage(validLpm2));
    };

    const fetchData = (ro, date) => {
        const formattedDate = date || new Date().toISOString().split('T')[0];
        axios.get(`http://farm.hdeng.net:4000/ro/table/${ro}?date=${formattedDate}`)
            .then(response => {
                console.log("로드된 데이터:", response.data.data); // 로드된 데이터 확인
                setData(response.data.data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setData([]);
            });
    };

    const handleDateSelect = (dateString) => {
        setSelectedDate(dateString);
    };

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).join(',');
        const rows = data.map(row =>
            Object.values(row).join(',')
        ).join('\n');

        return [headers].concat(rows).join('\n');
    };

    const downloadCSV = (data) => {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `ro-data-${selectedDate}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const toggleTable = () => {
        setShowTable(!showTable); // Toggle the visibility of the table
    };

    useEffect(() => {
        // 새로고침을 감지하는 이벤트 리스너
        const handleRefresh = (event) => {
            // 여기에 새로고침시 수행할 로직을 추가
            console.log("페이지가 새로고침되었습니다.");
            // 예: 데이터 다시 로드
            if (ro) {
                fetchData(ro, selectedDate);
            }
        };

        // 이벤트 리스너 등록
        window.addEventListener('beforeunload', handleRefresh);

        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
            window.removeEventListener('beforeunload', handleRefresh);
        };
    }, [ro, selectedDate]); // 의존성 배열에 필요한 변수 추가

    return (
        <Container fluid>
            {/* 사용자 이름 표시 */}
            <h3>{username}님의 정수시스템 데이터 입니다.</h3>
            <Row className="content-row">
                <Col>
                    <p>일일 총 퇴수량: {lastLpd1 > 1000 ? (lastLpd1 / 1000).toFixed(1) + ' ton' : `${lastLpd1} L`}</p>
                    <p>일일 총 정수량: {lastLpd2 > 1000 ? (lastLpd2 / 1000).toFixed(1) + ' ton' : `${lastLpd2} L`}</p>
                    <p>평균 퇴수량: {averageLpm1.toFixed(2)} L/M</p>
                    <p>평균 정수량: {averageLpm2.toFixed(2)} L/M</p>
                </Col>
            </Row>
            <h3> {selectedDate} 기록입니다.</h3>
            <Row className="content-row">
                <Col>
                    <RoSelect onDateSelect={handleDateSelect} />
                </Col>
            </Row>
            <Row className="content-row">
                <Col>
                    <RoChart data={data} />
                </Col>
            </Row>
            <Row className="content-row">
                <Col>
                    <Button className="period-button" onClick={toggleTable} variant="primary">
                        {showTable ? '상세 데이터 숨기기' : '상세 데이터 보기'}
                    </Button>
                    {/* Download CSV Button */}
                    <Button onClick={() => downloadCSV(data)} variant="info">
                        Download CSV
                    </Button>
                </Col>
            </Row>
            {showTable && (
                <Row className="content-row">
                    <Col>
                        <RoTable data={data} />
                    </Col>
                </Row>
            )}
            <h3>날짜별 데이터 분석입니다.</h3>
            <Row className="content-row" >
                <Col>
                    <RoAnalysis />
                </Col>
            </Row>
        </Container>
    );
};

export default RoUser;
