// VerifyToken.jsx
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyToken } from './auth';

const VerifyToken = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && !(location.pathname === '/login' || location.pathname === '/signup')) {
            verifyToken(token, dispatch, navigate).then(() => {
                // 비동기 작업 후 필요한 리디렉션 수행
            }).catch((error) => {
                console.error("토큰 검증 중 에러 발생:", error);
            });
        }
    }, [dispatch, navigate, location.pathname]);


    return null;
};

export default VerifyToken;
