import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux'; // Redux 훅 추가
import { Container, Row, Col, Table, Navbar, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import GpiChart from './GpiChart';
import useMediaQuery from '../useMediaQuery';
import SideBar from './SideBar';
import GpiLookup from './GpiLookup';
import GpiLive from './GpiLive';

function GpiMain() {
    // Redux 스토어에서 gpiData 상태를 가져옴
    const gpiDataFromStore = useSelector((state) => state.gpiValue);
    const [gpiData, setGpiData] = useState([]);
    const location = useLocation();

    const [selectedGpi, setSelectedGpi] = useState(() => location.state?.selectedGpi || null);
    const [selectedDate, setSelectedDate] = useState(() => {
        const initialDate = location.state?.selectedDate;
        return initialDate && !isNaN(Date.parse(initialDate)) ? new Date(initialDate) : new Date();
    });

    const handleDateChange = (date) => {
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        setSelectedDate(utcDate);
    };

    const [dbData, setDbData] = useState([]);
    const chartContainerRef = useRef(null);
    const [chartWidth, setChartWidth] = useState(() => chartContainerRef.current?.offsetWidth || 0);

    const apiClient = axios.create({
        baseURL: 'http://farm.hdeng.net:4000',
    });
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width: 800px)');

    const sidebarToggle = () => setSidebarOpen(!sidebarOpen);
    const [liveView, setLiveView] = useState(true);

    useEffect(() => {
        console.log('Received GPI data:', gpiData);
        // gpiDataFromStore가 배열인지 확인
        if (Array.isArray(gpiDataFromStore)) {
            setGpiData(gpiDataFromStore);
        } else {
            // 배열이 아닌 경우 빈 배열을 설정하거나, 적절한 로직으로 배열 변환
            setGpiData([]);
        }
    }, [gpiDataFromStore]);

    useEffect(() => {   // chartWidth 값이 변경될 때마다 실행
        const updateChartWidth = () => {
            if (chartContainerRef.current) {
                const containerWidth = 800;
                // const containerWidth = chartContainerRef.current.offsetWidth;
                setChartWidth(containerWidth);
            }
        };

        updateChartWidth();

        window.addEventListener('resize', updateChartWidth);

        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, []);

    useEffect(() => {
        if (location.state?.gpiData) {
            setGpiData(location.state.gpiData);
        }
        if (location.state?.selectedGpi) {
            setSelectedGpi(location.state.selectedGpi);
        }
        if (location.state?.selectedDate) {
            const initialDate = location.state.selectedDate;
            setSelectedDate(initialDate && !isNaN(Date.parse(initialDate)) ? new Date(initialDate) : new Date());
        }
    }, [location]);

    useEffect(() => {
        if (chartContainerRef.current) {
            setChartWidth(chartContainerRef.current.offsetWidth);
        }
    }, []);

    useEffect(() => {
        if (selectedGpi && !liveView) { // Only fetch data if not in live view
            const formattedSelectedDate = selectedDate.toISOString().split('T')[0];
            apiClient
                .post('/gpi/api/data', { gpi: selectedGpi, date: formattedSelectedDate })
                .then((response) => {
                    console.log('Successfully fetched data:', response.data);
                    setDbData(response.data);
                })
                .catch((error) => {
                    console.error('Failed to fetch data', error);
                    console.error('Error details:', error.response?.data);
                });
        }
    }, [selectedGpi, selectedDate, liveView]);

    const handleGpiClick = (gpi) => {
        setSelectedGpi(gpi);
        setLiveView(false); // Exit live view when switching GPIs
    };

    const getDateTime = (dateTimeStr) => {
        const [_, timePart] = dateTimeStr.split(' ');

        const [hours, minutes, seconds] = timePart.split(':');

        const date = new Date();

        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds);

        return date;
    };

    return (
        <Container fluid>
            <Navbar collapseOnSelect expand={false} expanded={sidebarOpen}>
                <Navbar.Brand as={Link} to="/">
                    결로 모니터링 시스템
                </Navbar.Brand>
                <Navbar.Toggle onClick={sidebarToggle} aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {isMobile && (
                            <SideBar
                                gpiData={gpiData}
                                selectedGpi={selectedGpi}
                                handleGpiClick={handleGpiClick}
                                selectedDate={selectedDate}
                                handleDateChange={handleDateChange}
                                dbData={dbData}
                                setLiveView={setLiveView}
                            />
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <Row>
                {!isMobile && (
                    <Col sm={3}>
                        <SideBar
                            gpiData={gpiData}
                            selectedGpi={selectedGpi}
                            handleGpiClick={handleGpiClick}
                            selectedDate={selectedDate}
                            handleDateChange={handleDateChange}
                            dbData={dbData}
                            setLiveView={setLiveView}
                        />
                    </Col>
                )}
                <Col sm={9}>
                    {!liveView ? (
                        <GpiLookup
                            selectedGpi={selectedGpi}
                            selectedDate={selectedDate}
                            chartContainerRef={chartContainerRef}
                            chartWidth={chartWidth} // chartWidth 값을 전달
                            dbData={dbData}
                            getDateTime={getDateTime}
                        />
                    ) : (
                        <GpiLive gpiData={gpiData} setLiveView={setLiveView} />
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default GpiMain;
