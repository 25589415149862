import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const RoSelect = ({ onDateSelect }) => {
    const [startDate, setStartDate] = useState(new Date());

    const handleDateChange = (date) => {
        // 현지 시간대의 오프셋(분)을 구합니다.
        const offset = date.getTimezoneOffset();

        // 현지 시간대의 오프셋을 적용하여 날짜 객체를 수정합니다.
        // getTimezoneOffset()은 현지 시간이 UTC보다 앞서면 음수를,
        // 뒤쳐지면 양수를 반환하기 때문에, offset을 뺍니다.
        date = new Date(date.getTime() - (offset * 60 * 1000));

        // 수정된 날짜를 ISO 형식으로 변환하여 날짜 부분만 추출합니다.
        const formattedDate = date.toISOString().split('T')[0];

        setStartDate(date); // DatePicker에 선택된 날짜를 업데이트합니다.
        onDateSelect(formattedDate); // 선택된 날짜를 부모 컴포넌트로 전달합니다.
    };

    return (
        <div>
            <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                dateFormat="yyyy/MM/dd" // 사용자에게 보여주는 포맷
            />
        </div>
    );
};

export default RoSelect;
