import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import GpiChart from './GpiChart';

function GpiLookup({ selectedGpi, selectedDate, chartContainerRef, chartWidth, dbData, getDateTime }) {
    const [showTable, setShowTable] = useState(false);

    if (!selectedGpi) {
        return null;
    }

    const handleToggleTable = () => {
        setShowTable(!showTable);
    };

    return (
        <>
            <h3>센서번호 {selectedGpi}</h3>
            <h3>조회 날짜 {selectedDate.toLocaleDateString()}</h3>
            <div ref={chartContainerRef}>
                <GpiChart
                    width={500}
                    height={300}
                    data={dbData}
                />
            </div>
            <Button onClick={handleToggleTable}>
                데이터 상세 보기
            </Button>
            {showTable && (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>시각</th>
                            <th>건구</th>
                            <th>습구</th>
                            <th>과실</th>
                            <th>토양</th>
                            <th>물통</th>
                            <th>HD</th>
                            <th>이슬점</th>
                            <th>CO2</th>
                            <th>습도</th>
                            <th>결로점</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dbData.map((data, index) => (
                            <tr key={index}>
                                <td>{getDateTime(data.opTime).toLocaleTimeString()}</td>
                                <td>{data.EI_FG_TI}</td>
                                <td>{data.EI_FG_TW}</td>
                                <td>{data.EI_FG_TF}</td>
                                <td>{data.EI_FG_TS}</td>
                                <td>{data.EI_FG_TL === 1 ? "O" : "X"}</td>
                                <td>{data.EI_FG_HD}</td>
                                <td>{data.EI_FG_DP}</td>
                                <td>{data.EI_FG_CI}</td>
                                <td>{data.EI_FG_HI}</td>
                                <td>{data.EI_FG_GP}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </>
    );
}

export default GpiLookup;
