import React, { useEffect } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setIsLoggedIn } from '../redux/actions';

function Navigation() {
    const isLoggedIn = useSelector(state => state.isLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!isLoggedIn && location.pathname !== '/login') {
            navigate('/login');
        }
    }, [isLoggedIn, location.pathname, navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        dispatch(setIsLoggedIn(false));
        navigate('/login');
    };

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/">모니터링시스템</Navbar.Brand>
                <span className="navbar-text ml-2" style={{ color: 'gray' }}>v0.3</span>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/gpi">결로모니터</Nav.Link>
                        <Nav.Link as={Link} to="/gpi/list">GpiList</Nav.Link>
                        <Nav.Link as={Link} to="/ro/user">정수기</Nav.Link>
                        {/* <Nav.Link as={Link} to="/ro/admin">관리자</Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/ro/customer">고객</Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/signup">회원가입</Nav.Link> */}
                    </Nav>
                    {isLoggedIn ? (
                        <Button variant="outline-info" onClick={handleLogout} aria-label="로그아웃">
                            로그아웃
                        </Button>
                    ) : (
                        <Button variant="outline-info" onClick={() => navigate('/login')} aria-label="로그인">
                            로그인
                        </Button>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;

// v0.3 gpiLive 요약 테이블 추가, 맨 하단 용어 설명 추가, 센서 별칭 추가