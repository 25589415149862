import React from 'react';
import { Button, Form } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { CSVLink } from 'react-csv';

function SideBar({ gpiData, selectedGpi, handleGpiClick, selectedDate, handleDateChange, dbData, setLiveView }) {
    const handleGpiLiveClick = () => {
        setLiveView(true);
    };

    return (
        <div>
            <Form.Group>
                <Form.Label>Date</Form.Label>
                <Calendar onChange={handleDateChange} value={selectedDate} />
            </Form.Group>
            <br />
            <h2>센서 목록</h2>
            {gpiData.map((gpiItem, index) => (
                <Button
                    key={index}
                    onClick={() => handleGpiClick(gpiItem)}
                    variant={selectedGpi === gpiItem ? 'primary' : 'secondary'}
                    className="d-block w-100 mb-2" // 'mb-2' 클래스는 버튼 사이의 마진을 추가합니다.
                >
                    {gpiItem}
                </Button>
            ))}
            <br />
            <br />
            <h2>CSV 엑셀 출력</h2>
            <CSVLink data={dbData} filename={'my-data.csv'} className="btn btn-primary d-block w-100 mb-2">
                다운로드
            </CSVLink>
            <br />
            <br />
            <h2>실시간 조회</h2>
            <Button onClick={handleGpiLiveClick} variant="info" className="w-100 mb-2">
                조회하기
            </Button>
        </div>
    );
}

export default SideBar;
