import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './LoginPage.css';
import { setIsLoggedIn, setUsernameAction, setRoValue, setGpiValue } from '../redux/actions';

function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const verifyToken = async (token) => {
        console.log('토큰 검증 시작:', token); // 토큰 검증 시작 로깅
        if (!token) {
            console.log('토큰이 없습니다.1'); // 토큰 부재 로깅
            return;
        }

        try {
            const response = await axios.post('http://farm.hdeng.net:4000/api/auth/verifyToken', {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('토큰 검증 응답:', response.data); // 토큰 검증 성공 로깅

            if (response.status === 200 && response.data.user) {
                console.log("토큰 검증 성공:", response.data);
                const user = response.data.user;

                dispatch(setIsLoggedIn(true));
                dispatch(setUsernameAction(user.username));
                dispatch(setRoValue(user.ro));

                // GPI 값 처리
                let gpiValue = user.gpi && typeof user.gpi === 'string' ? user.gpi.split(', ') : [];
                dispatch(setGpiValue(gpiValue));

                // 리디렉션 결정
                if (user.gpi) {
                    navigate('/gpi');
                } else {
                    navigate('/ro/user');
                }
            }
        } catch (error) {
            console.error("토큰 검증 실패, 오류:", error);
            localStorage.removeItem('token');
            dispatch(setIsLoggedIn(false));
            navigate('/login'); // 토큰 검증 실패 시 로그인 페이지로 리디렉션
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        console.log('로그인 시도 시작'); // 로그인 시도 시작 로깅
        if (isLoggingIn) {
            console.log('이미 로그인 중입니다.'); // 중복 로그인 시도 방지 로깅
            return;
        }
        setIsLoggingIn(true);
        setErrorMessage('');

        console.log('사용자 입력 정보:', { username, password }); // 사용자 입력 정보 로깅

        try {
            const loginData = { username, password };
            console.log('서버로 로그인 요청 전송:', loginData); // 서버 요청 정보 로깅
            const response = await axios.post('http://farm.hdeng.net:4000/auth/login', loginData);
            console.log('로그인 요청 응답:', response); // 서버 응답 로깅

            if (response.data.message === 'Login successful') {
                console.log('로그인 성공:', response.data); // 로그인 성공 로깅
                localStorage.setItem('token', response.data.token);
                // 성공적인 로그인 후 토큰 검증
                verifyToken(response.data.token);
            } else {
                console.log('로그인 실패:', response.data.message); // 서버 측 로그인 실패 로깅
                setErrorMessage('로그인 실패: ' + response.data.message);
            }
        } catch (error) {
            console.error("로그인 요청 중 오류 발생:", error); // 오류 발생 시 로깅
            setErrorMessage("로그인 과정에서 문제가 발생했습니다. 다시 시도해 주세요.");
        } finally {
            setIsLoggingIn(false);
            console.log('로그인 시도 종료'); // 로그인 시도 종료 로깅
        }
    };

    const handleUsernameChange = (e) => setUsername(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    return (
        <div className="login-container">
            <div className="login-card">
                <h1>로그인</h1>
                <Form onSubmit={handleLogin}>
                    <Form.Group controlId="formUsername">
                        <Form.Label>아이디</Form.Label>
                        <Form.Control type="text" placeholder="홍길동" value={username} onChange={handleUsernameChange} />
                    </Form.Group>

                    <Form.Group controlId="formPassword">
                        <Form.Label>비밀번호</Form.Label>
                        <Form.Control type="password" placeholder="1234" value={password} onChange={handlePasswordChange} />
                    </Form.Group>

                    {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}

                    <Button type="submit" variant="primary" className="mt-3 w-100" disabled={isLoggingIn}>
                        로그인
                    </Button>
                </Form>
                {/* 회원 가입 링크 추가 */}
                <div className="mt-4 text-center">
                    <span>아직 회원이 아니신가요? </span>
                    <Link to="/signup">회원가입</Link>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
