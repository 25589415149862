// CustomerCsv.js
import React, { useRef } from 'react';
import Papa from 'papaparse';
import { Button } from 'react-bootstrap';
import axios from 'axios';

function CustomerCsv({ refreshData }) {
    const fileInputRef = useRef(null);

    const handleCsvUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                complete: (result) => {
                    // Filter out empty or incomplete rows
                    const filteredData = result.data.filter(item =>
                        Object.values(item).some(value => value && value.trim() !== '')
                    );

                    console.log('Filtered CSV Result:', filteredData);
                    axios.post('http://farm.hdeng.net:4000/ro/customer/csv', { data: filteredData })
                        .then(() => {
                            refreshData();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                },
                header: true
            });
        }
    };

    return (
        <div>
            <input type="file" ref={fileInputRef} onChange={handleCsvUpload} style={{ display: 'none' }} />
            <Button onClick={() => fileInputRef.current.click()}>Upload CSV</Button>
        </div>
    );
}

export default CustomerCsv;
