// src/redux/actions.js

export const SET_RO_VALUE = 'SET_RO_VALUE';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN'; // 새 액션 타입
export const SET_GPI_VALUE = 'SET_GPI_VALUE'; // 추가된 액션 타입

export const setRoValue = (ro) => ({
    type: SET_RO_VALUE,
    payload: ro,
});

export const setUsernameAction = (username) => ({
    type: SET_USERNAME,
    payload: username,
});

// 로그인 상태 업데이트 액션 생성자
export const setIsLoggedIn = (isLoggedIn) => ({
    type: SET_IS_LOGGED_IN,
    payload: isLoggedIn,
});

// GPI 값 설정 액션 생성자
export const setGpiValue = (gpi) => ({
    type: SET_GPI_VALUE,
    payload: gpi,
});
