import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, InputGroup } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function SignUp() {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        mobile: '',
        address: '',
        gpi: '',
        ro: '',
        smart: '',
        jet: '',
        fog: ''
        // profile_image: null, // 프로필 이미지 관련 로직이 구현되지 않았으므로 제외
    });

    const navigate = useNavigate();

    function transformModelSerialNumbers(serialNumbers) {
        if (!serialNumbers.trim()) {
            // 빈 문자열이거나 공백만 있는 경우, 오류를 발생시키지 않고 빈 문자열을 반환
            return '';
        }

        // 변환 로직은 이전과 동일
        let transformed = serialNumbers.replace(/\./g, ',').replace(/,\s*/g, ', ').trim();

        // 변환된 문자열 로깅
        console.log(`변환된 시리얼 번호: ${transformed}`);

        // 변환된 문자열이 올바른 형식인지 검증
        const isValid = /^(\w+(, )?)+$/.test(transformed);
        if (!isValid) {
            console.error(`변환 오류: 올바른 형식이 아닙니다. 입력값: '${serialNumbers}', 변환값: '${transformed}'`);
            throw new Error('변환된 모델 시리얼 번호가 올바른 형식이 아닙니다.');
        }

        return transformed;
    }

    function transformMobileNumber(mobileNumber) {
        return mobileNumber.replace(/-/g, '');
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('회원가입 시도:', formData); // 제출 시도 로깅

        try {
            const transformedData = {
                ...formData,
                mobile: transformMobileNumber(formData.mobile),
                gpi: transformModelSerialNumbers(formData.gpi),
                ro: transformModelSerialNumbers(formData.ro),
                smart: transformModelSerialNumbers(formData.smart),
                jet: transformModelSerialNumbers(formData.jet),
                fog: transformModelSerialNumbers(formData.fog),
            };

            console.log('서버로 보내는 데이터:', transformedData); // 전송 데이터 로깅
            const response = await axios.post('http://farm.hdeng.net:4000/auth/register', transformedData);
            console.log('회원가입 성공:', response.data); // 성공 로깅
            alert('회원가입에 성공했습니다. 로그인으로 이동합니다.');
            navigate('/login'); // 로그인 페이지로 리다이렉트
        } catch (error) {
            console.error('회원가입 실패:', error); // 실패 로깅
            console.error('실패 응답:', error.response ? error.response.data : '응답 없음');
            alert(`회원가입에 실패했습니다. ${error.response?.data?.message || '다시 시도해 주세요.'}`);
        }
    };

    const basicFields = [
        { label: '사용자 이름', name: 'username', type: 'text', placeholder: '사용자 이름 입력', isInputGroup: false },
        { label: '이메일 주소', name: 'email', type: 'email', placeholder: '이메일 입력', isInputGroup: false },
        { label: '비밀번호', name: 'password', type: 'password', placeholder: '비밀번호', isInputGroup: false, minLength: 4 },
        { label: '휴대폰 번호 (선택 사항)', name: 'mobile', type: 'text', placeholder: '휴대폰 번호', isInputGroup: false },
        { label: '주소 (선택 사항)', name: 'address', type: 'text', placeholder: '주소', isInputGroup: false },
        { label: '프로필 이미지 (선택 사항)', name: 'profile_image', type: 'file', isInputGroup: false },
    ];

    const modelFields = [
        { label: 'GPI 모델 시리얼 번호', name: 'gpi', type: 'text', placeholder: 'GPI 모델 시리얼 번호', isInputGroup: true },
        { label: 'RO 모델 시리얼 번호', name: 'ro', type: 'text', placeholder: 'RO 모델 시리얼 번호', isInputGroup: true },
        { label: 'SMART 모델 시리얼 번호', name: 'smart', type: 'text', placeholder: 'SMART 모델 시리얼 번호', isInputGroup: true },
        { label: 'JET 모델 시리얼 번호', name: 'jet', type: 'text', placeholder: 'JET 모델 시리얼 번호', isInputGroup: true },
        { label: 'FOG 모델 시리얼 번호', name: 'fog', type: 'text', placeholder: 'FOG 모델 시리얼 번호', isInputGroup: true },
    ];

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs={12} md={8}>
                    <h2>회원 가입</h2>
                    <Form onSubmit={handleSubmit}>
                        {basicFields.map(field => (
                            <Form.Group controlId={`formBasic${field.name}`} key={field.name}>
                                <Form.Label>{field.label}</Form.Label>
                                <Form.Control
                                    type={field.type}
                                    placeholder={field.placeholder}
                                    name={field.name}
                                    value={formData[field.name]}
                                    onChange={handleChange}
                                    minLength={field.minLength || undefined} />
                            </Form.Group>
                        ))}

                        <hr /> {/* 섹션 구분선 */}

                        <h4 className="mb-3">모델 등록</h4> {/* 섹션 제목 */}
                        {modelFields.map(field => (
                            <InputGroup className="mb-3" key={field.name}>
                                <Form.Control
                                    placeholder={field.placeholder}
                                    aria-label={field.placeholder}
                                    name={field.name}
                                    value={formData[field.name]}
                                    onChange={handleChange} />
                            </InputGroup>
                        ))}

                        <Button variant="primary" type="submit">
                            가입하기
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default SignUp;
