import React, { useEffect, useState } from 'react';
import { fetchTables, fetchLatestData } from './GpiApi'; // API 파일 경로에 따라 수정
import axios from 'axios';
import { Container, Accordion, Button, Spinner, Alert, Card } from 'react-bootstrap';

// 현재 시각과 opTime을 비교하여 상태를 계산
const calculateStatus = (opTime) => {
    if (!opTime) return <span style={{ color: 'gray', fontWeight: 'bold' }}>[없음]</span>;

    const now = new Date();
    const opTimeDate = new Date(opTime);
    const diffMinutes = (now - opTimeDate) / (1000 * 60); // 차이를 분 단위로 계산

    return diffMinutes <= 10
        ? <span style={{ color: 'green', fontWeight: 'bold' }}>[원활]</span>
        : <span style={{ color: 'red', fontWeight: 'bold' }}>[지연]</span>;
};

const GpiList = () => {
    const [tables, setTables] = useState([]);
    const [latestData, setLatestData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadTablesAndData = async () => {
            try {
                setLoading(true);
                const tableList = await fetchTables();
                setTables(tableList);

                const latestDataPromises = tableList.map(async (table) => {
                    try {
                        const data = await fetchLatestData(table);
                        return { table, data };
                    } catch (err) {
                        console.error(`[ERROR] Failed to fetch latest data for table: ${table}`, err);
                        return { table, data: null };
                    }
                });

                const allLatestData = await Promise.all(latestDataPromises);
                const latestDataMap = allLatestData.reduce((acc, { table, data }) => {
                    acc[table] = data;
                    return acc;
                }, {});
                setLatestData(latestDataMap);
            } catch (err) {
                setError('Failed to load tables and latest data. Please try again.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        loadTablesAndData();
    }, []);

    const downloadCSV = async (table) => {
        try {
            const response = await axios.get(`http://farm.hdeng.net:4000/gpi/api/data/download?table=${table}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${table}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (err) {
            console.error(`[ERROR] Failed to download CSV for table: ${table}`, err);
        }
    };

    if (loading) {
        return (
            <Container className="text-center mt-5">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p>Loading tables and data...</p>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="mt-5">
                <Alert variant="danger">{error}</Alert>
            </Container>
        );
    }

    return (
        <Container className="mt-5">
            <Card>
                <Card.Header as="h2" className="text-center">
                    GPI Tables and Latest Data
                </Card.Header>
                <Card.Body>
                    {tables.length > 0 ? (
                        <Accordion>
                            {tables.map((table, index) => (
                                <Accordion.Item eventKey={index.toString()} key={table}>
                                    <Accordion.Header>
                                        <div style={{ flex: 1 }}>
                                            {calculateStatus(latestData[table]?.opTime)} Table: {table} -{' '}
                                            {latestData[table]?.opTime
                                                ? `opTime: ${latestData[table].opTime}`
                                                : 'No data available'}{' '}
                                            - 온도: {latestData[table]?.EI_FG_TI !== undefined ? latestData[table].EI_FG_TI : 'N/A'}
                                        </div>
                                        <Button
                                            variant="primary"
                                            size="sm"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent accordion toggle on button click
                                                downloadCSV(table);
                                            }}
                                            className="ms-3"
                                        >
                                            Download CSV
                                        </Button>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {latestData[table] ? (
                                            <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                                                {JSON.stringify(latestData[table], null, 2)}
                                            </pre>
                                        ) : (
                                            <p>No detailed data available for this table.</p>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    ) : (
                        <Alert variant="warning">No tables found.</Alert>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default GpiList;
