import React from 'react';
import { Button } from 'react-bootstrap';

function downloadCSV(data) {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    for (const row of data) {
        const values = headers.map(header => {
            let value = row[header] || ''; // null이나 undefined를 빈 문자열로 처리
            value = value.toString(); // 숫자나 다른 데이터 타입을 문자열로 변환
            value = value.replace(/"/g, '""'); // 따옴표를 두 개의 따옴표로 대체
            return `"${value}"`; // 모든 데이터를 따옴표로 감싸기
        });
        csvRows.push(values.join(','));
    }

    const csvString = "\uFEFF" + csvRows.join('\r\n'); // UTF-8 BOM 추가
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'customer_list.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

function CustomerCsvDown({ data }) {
    return (
        <Button variant="secondary" onClick={() => downloadCSV(data)}>
            Download CSV
        </Button>
    );
}

export default CustomerCsvDown;
