import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useSelector } from 'react-redux';

const RoAnalysis = () => {
    const [chartData, setChartData] = useState([]);
    const ro = useSelector(state => state.roValue);
    const [period, setPeriod] = useState('7d');
    const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가

    useEffect(() => {
        fetchDataForPeriod(period);
    }, [ro, period]);

    const fetchDataForPeriod = async (selectedPeriod) => {
        setIsLoading(true); // 데이터 로딩 시작
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - (selectedPeriod === '7d' ? 6 : selectedPeriod === '30d' ? 29 : 89));

        let dates = [];
        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            dates.push(new Date(date));
        }

        const promises = dates.map(date => {
            let formattedDate = date.toISOString().split('T')[0];
            return fetchDailyData(ro, formattedDate);
        });

        const tempChartData = await Promise.all(promises);
        setChartData(tempChartData);
        setIsLoading(false); // 데이터 로딩 완료
    };


    const fetchDailyData = async (ro, date) => {
        try {
            const response = await axios.get(`http://farm.hdeng.net:4000/ro/table/${ro}?date=${date}`);
            const dailyData = response.data.data;
            return calculateMetrics(dailyData, date);
        } catch (error) {
            console.error("Error fetching data for date " + date + ": ", error);
            return { date, lastLpd1: 0, lastLpd2: 0, averageLpm1: 0, averageLpm2: 0 };
        }
    };

    const calculateMetrics = (data, date) => {
        let validLpm1 = data.filter(d => d.lpm1 > 0).map(d => parseFloat(d.lpm1));
        let validLpm2 = data.filter(d => d.lpm2 > 0).map(d => parseFloat(d.lpm2));

        const calculateTrimmedAverage = (array) => {
            const sortedArray = array.sort((a, b) => a - b);
            const lowerIndex = Math.floor(array.length * 0.2);
            const upperIndex = Math.ceil(array.length * 0.8);
            const trimmedArray = sortedArray.slice(lowerIndex, upperIndex);
            return trimmedArray.length > 0 ? trimmedArray.reduce((a, b) => a + b, 0) / trimmedArray.length : 0;
        };

        let averageLpm1 = calculateTrimmedAverage(validLpm1);
        let averageLpm2 = calculateTrimmedAverage(validLpm2);

        let lastDataEntry = data.length > 0 ? data[data.length - 1] : null;
        let lastLpd1 = lastDataEntry ? lastDataEntry.lpd1 / 1000 : 0; // 1000으로 나누어 톤 단위로 변환
        let lastLpd2 = lastDataEntry ? lastDataEntry.lpd2 / 1000 : 0; // 1000으로 나누어 톤 단위로 변환
        return { date, lastLpd1, lastLpd2, averageLpm1, averageLpm2 };
    };

    return (
        <Container fluid >
            {isLoading ? (
                <Row>
                    <Col className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="sr-only"> </span>
                        </Spinner>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={chartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="lastLpd1" stroke="#8884d8" strokeWidth={3} name="퇴수합계(일/톤)" dot={false} />
                                <Line type="monotone" dataKey="lastLpd2" stroke="#82ca9d" strokeWidth={3} name="정수합계(일/톤)" dot={false} />
                                <Line type="monotone" dataKey="averageLpm1" stroke="#8884d8" name="퇴수평균(L/분)" dot={false} />
                                <Line type="monotone" dataKey="averageLpm2" stroke="#82ca9d" name="정수평균(L/분)" dot={false} />
                            </LineChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Button className="period-button" onClick={() => setPeriod('7d')}>7 Days</Button>
                    <Button className="period-button" onClick={() => setPeriod('30d')}>30 Days</Button>
                    <Button onClick={() => setPeriod('90d')}>90 Days</Button>
                </Col>
            </Row>
        </Container>
    );
};

export default RoAnalysis;
