import React from 'react';
import { Table } from 'react-bootstrap';

const glossaryData = [
    { term: '전송시각', definition: '데이터가 전송된 시각을 나타냅니다. 이는 센서가 데이터를 서버로 전송한 시간입니다.' },
    { term: '건구온도', definition: '건구온도는 공기의 온도를 측정한 값으로, 습도를 고려하지 않은 순수한 공기 온도를 의미합니다.' },
    { term: '습구온도', definition: '습구온도는 젖은 심지로 감싼 온도센서의 온도를 측정한 값으로, 이는 증발 냉각 효과를 반영합니다.' },
    { term: '과실온도', definition: '과실온도는 모형 과실 표면의 온도를 측정하고 실제 과실의 표면 온도를 추측하여 계산한 값입니다.' },
    { term: '토양온도', definition: '토양온도는 땅 속의 온도를 나타냅니다. 이는 뿌리 생장과 영양분 흡수에 중요한 역할을 합니다.' },
    { term: '물통', definition: '물통의 수위는 습구 온도 센서에 수분을 공급하기 위한 물통에 저장된 물의 양을 나타냅니다. 부족할 경우 보충해 주어야 합니다.' },
    { term: 'HD', definition: 'HD (Humidity Deficit)는 공기 중 포화 수증기량과 실제 수증기량의 차이를 나타냅니다. 이는 식물의 증산율과 관련이 있으며, 최적의 HD 범위는 4-8 g/m³입니다.' },
    { term: '이슬점', definition: '이슬점은 공기가 포화 상태가 되어 물방울이 형성되기 시작하는 온도입니다. 이는 공기 중의 습도와 온도에 따라 달라집니다.' },
    { term: 'CO2', definition: 'CO2 농도는 공기 중 이산화탄소의 농도를 나타내며, 식물의 광합성에 중요한 역할을 합니다.' },
    { term: '습도', definition: '습도는 공기 중의 수분 함량을 나타냅니다. 상대 습도는 현재 공기의 습도 수준을 백분율로 나타낸 것입니다.' },
    { term: '결로점', definition: '결로점은 과실온도-이슬점을 계산한 값으로 결로 상태를 나타내기 위한 값입니다.' }
];

const optimalConditions = [
    { parameter: '건구온도', optimalValue: '25-30℃' },
    { parameter: '습구온도', optimalValue: '20-25℃' },
    { parameter: '과실온도', optimalValue: '18-25℃' },
    { parameter: '토양온도', optimalValue: '15-20℃' },
    { parameter: '이슬점', optimalValue: '10-15℃' },
    { parameter: 'CO2', optimalValue: '400-800ppm' },
    { parameter: '습도', optimalValue: '60-80%' },
    { parameter: 'HD', optimalValue: '4-8 g/m³' } // 추가된 내용
];

const Glossary = () => {
    return (
        <div style={{ padding: '20px' }}>
            <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#333' }}>용어와 해설</h3>
            {glossaryData.map((item, index) => (
                <div key={index} style={{ marginBottom: '15px' }}>
                    <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#007bff' }}>{item.term}</span>:&nbsp;
                    <span style={{ fontSize: '18px', color: '#555' }}>{item.definition}</span>
                </div>
            ))}
            <h4 style={{ fontSize: '22px', fontWeight: 'bold', color: '#333', marginTop: '20px' }}>결로점 상태</h4>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>상태</th>
                        <th>설명</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>우수</td>
                        <td style={{ fontSize: '18px', color: '#555' }}>결로점과 이슬점의 차이가 3도 이상 5도 미만일 때.</td>
                    </tr>
                    <tr>
                        <td style={{ fontSize: '18px', fontWeight: 'bold', color: '#28a745' }}>양호</td>
                        <td style={{ fontSize: '18px', color: '#555' }}>결로점과 이슬점의 차이가 1도 이상 3도 미만일 때.</td>
                    </tr>
                    <tr>
                        <td style={{ fontSize: '18px', fontWeight: 'bold', color: '#fd7e14' }}>주의</td>
                        <td style={{ fontSize: '18px', color: '#555' }}>결로점과 이슬점의 차이가 0도 이상 1도 미만일 때.</td>
                    </tr>
                    <tr>
                        <td style={{ fontSize: '18px', fontWeight: 'bold', color: '#dc3545' }}>결로발생</td>
                        <td style={{ fontSize: '18px', color: '#555' }}>결로점과 이슬점의 차이가 0도 미만일 때.</td>
                    </tr>
                </tbody>
            </Table>
            <h4 style={{ fontSize: '22px', fontWeight: 'bold', color: '#333', marginTop: '20px' }}>식물 생장의 최적 조건</h4>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>파라미터</th>
                        <th>최적값</th>
                    </tr>
                </thead>
                <tbody>
                    {optimalConditions.map((item, index) => (
                        <tr key={index}>
                            <td style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>{item.parameter}</td>
                            <td style={{ fontSize: '18px', color: '#555' }}>{item.optimalValue}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div style={{ marginTop: '20px' }}>
                <h4 style={{ fontSize: '22px', fontWeight: 'bold', color: '#333' }}>강원도 농업기술원 결로모니터링 시스템</h4>
                <p style={{ fontSize: '18px', color: '#555' }}>
                    결로모니터링 시스템은 강원도 농업기술원에서 개발한 기술로 시설과채류 재배 시 발생하는 결로 현상을 모니터링하고 방지하여 품질 향상 및 소득 증대를 목표로합니다.
                    이 시스템은 웹과 앱을 통해 실시간 환경 확인이 가능하며, 결로 발생을 예측하고 환경 제어를 통해 억제합니다.
                    현장 실증을 통해 효과를 검증했으며, 향후 시설 내 환경 통합 제어 시스템으로 고도화할 계획입니다.
                    에이텍(주)는 결로모니터링 시스템을 기술이전 받아 보급하고 있습니다.
                </p>
            </div>
        </div>
    );
};

export default Glossary;
