import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { store } from './redux/store';
import Navigation from './components/Navigation';
import LoginPage from './pages/LoginPage';
import GpiMain from './pages/gpi/GpiMain';
import CustomerList from './pages/ro/CustomerList';
import RoAdmin from './pages/ro/RoAdmin';
import RoUser from './pages/ro/RoUser';
import SignUp from './pages/Account/SignUp'; // SignUp 컴포넌트 import
import VerifyToken from './utils/VerifyToken';
import GpiList from './pages/gpi/GpiList'; // GpiList 컴포넌트 import

function App() {
  return (
    <Provider store={store}>
      <Router>
        <VerifyToken />
        <Navigation />
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUp />} /> {/* 회원가입 경로 추가 */}
          <Route path="/gpi" element={<GpiMain />} />
          <Route path="/gpi/list" element={<GpiList />} /> {/* GpiList 경로 추가 */}
          <Route path="/ro/customer" element={<CustomerList />} />
          <Route path="/ro/admin" element={<RoAdmin />} />
          <Route path="/ro/user" element={<RoUser />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
