import axios from 'axios';

// 최신 데이터 가져오기
export const fetchLatestData = async (gpi) => {
    try {
        const response = await axios.get(`http://farm.hdeng.net:4000/gpi/api/data/latest?gpi=${gpi}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch latest data:', error);
        throw error;
    }
};

// 특정 GPI의 cname 가져오기
export const fetchCname = async (gpi) => {
    try {
        const response = await axios.get(`http://farm.hdeng.net:4000/gpi/api/topic/cname/${gpi}`);
        return response.data.cname;
    } catch (error) {
        console.error('Failed to fetch cname:', error);
        throw error;
    }
};

// 특정 GPI의 cname 업데이트
export const updateCname = async (gpi, cname) => {
    try {
        await axios.put(`http://farm.hdeng.net:4000/gpi/api/topic/cname`, {
            topic: gpi,
            cname: cname,
        });
    } catch (error) {
        console.error('Failed to update cname:', error.response || error.message || error);
        throw error;
    }
};

// 모든 테이블 목록 가져오기
export const fetchTables = async () => {
    try {
        const response = await axios.get(`http://farm.hdeng.net:4000/gpi/api/tables`);
        return response.data.tables;
    } catch (error) {
        console.error('Failed to fetch tables:', error);
        throw error;
    }
};
